<template>
  <enhanced-locator-page-map-container
    map-classes="b2c-map"
    :location-coords="{
      lat: location.latitude,
      lng: location.longitude,
    }"
    :zoom="defaultMapZoom"
    :store-details="storeDetails"
    qaid="enhanced-locator-page-map-canvas"
  />
</template>

<script>
import EnhancedLocatorPageMapContainer from '@/components/mapbox/containers/EnhancedLocatorPageMapContainer';

const DEFAULT_MAP_ZOOM = 16;

export default {
  components: {
    EnhancedLocatorPageMapContainer,
  },

  props: {
    storeLatLng: {
      type: Object,
      default: () => null,
    },
    storeDetails: {
      type: Object,
      default: () => null,
    },
    isMapboxEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      storeCoords: this.storeLatLng,
      defaultMapZoom: DEFAULT_MAP_ZOOM,
    };
  },

  computed: {
    location() {
      return {
        latitude: parseFloat(this.storeCoords?.latitude),
        longitude: parseFloat(this.storeCoords?.longitude),
      };
    },
  },
};
</script>

<style scoped lang="scss">
.b2c-map {
  width: 100%;
  height: 300px;
}
</style>
